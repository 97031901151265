.modal {
  overflow: scroll !important;
}

.sticky-header {
  position: sticky;
  position: -webkit-sticky;
  background-color: white;
  left: 0px;
}
.sticky-col {
  position: sticky;
  position: -webkit-sticky;
  background-color: #fbfbfb;
  left: 0px;
}

.fixed-size-column {
  min-width: 200px !important;
  max-width: 300px !important;
}

.ant-tooltip-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.ant-tooltip-content {
  display: flex;
  max-width: 600px;
}

.ant-tag {
  margin-top: 1%;
  margin-bottom: 1%;
}

.table-row-size {
  height: 54px;
}

.extension-loading-progress{
  width: 50%;
  text-align: center;
  margin: 300px auto 0px auto;
}

.extension-loading-progress div{
  margin-bottom: 20px;
}

.ant-avatar-circle {
  border: 1px solid rgba(0,0,0,0.1);
}

.ant-avatar-image img {
  object-fit: contain;
}

.ant-select-arrow-loading {
  margin-right: 18px;
}

.scoring-preview-min-width-score {
  min-width: 90px;
}
.scoring-preview-min-width-cell {
  min-width: 180px;
}

.clickable-table-link{
  color: #222 !important; 
}
.clickable-table-link:hover{
  text-decoration: underline !important;
}

.jsoneditor .ace_editor{
  min-height: 500px;
}