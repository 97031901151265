.market-estimations {
    /*border: 1px solid darkgrey;*/
    line-height: 1;
}

/* boxes */
.sourcing .align-items-center {
    padding: 8px 0 0 0;
    border: 1px solid darkgrey;
    /*border-top: 1px solid darkgrey;*/
    align-items: flex-start;
    justify-content: space-between;
}

.sourcing .align-items-center .py-4 {
    align-self: flex-start;
    padding: 0 0 0 0 !important;
    margin-right: 5rem;
}


button > svg {
    /*width: 10px;*/
    font-size: 0.5rem;
}

.sourcing .col-9 {
    max-width: 25%;
    /*float:right*/
}

.sourcing .col-3 {
    max-width: 0;
    /*float:right*/
}

.sourcing select {
    padding: 0 0 0 0;
    height: 1.5rem;
    width: 10rem;
}

.sourcing > div > label {
    display: none
}

.market-estimations .not {
    display: none
}

/* Group / Property select*/
.sourcing > div > div > div > select {
    color: #096dd9;
    background: #e6f7ff;
    border-color: #91d5ff;
    text-align: center;
    text-transform: capitalize;
}

/* AND/ OR select */
.combinator > div > select {
    color: #fff;
    background: rgb(135, 208, 104);
    border-color: rgb(135, 208, 104);
    text-align: center;
    text-transform: uppercase;
    margin-right: 1rem;
    width: 3rem
}

.combinator > div > label {
    /*background-color: #6b36ff;*/
    display: none
}

.sourcing label {
    padding: 0 0 0 0;
    height: 22px;
    margin: 0 0 0 0;
}

.sourcing input {
    padding: 0 0 0 0;
    height: 22px
}

.form-group {
    margin: 0 0 0 0;
}

.sourcing .form-group {
    margin-bottom: 4px;
    margin-right: 4px;
}

.field-string > div > .form-control {
    margin-top: 2px;
}

.my-1 {
    display: none;
}

.mt-2 > .col-9 {
    display: none;
}

.mt-2 > .col-3 {
    flex: unset;
    max-width: 100%;
}

.mt-2 > .col-3 > button {
    padding: 0 0 0 0;
}

/* to make buttons look like default primary buttons in ant-d */
.btn-primary {
    color: #fff;
    background: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

/**/
.searchable-select {
    width: 10rem;
    height: 1.5rem;
    font-size: 0.9375rem;
    line-height: 1.6;
}


.searchable-select > div {
    padding: 0 0 0 0;
    min-height: unset;
}

.searchable-select > div div {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
    font-size: 0.9375rem;
    line-height: 1.6;
}

.searchable-select div[class^="searchable-select__menu"] {
    width: 20rem;
    max-height: 10rem;
}


/* rules nesting layout */
/* start level 1*/
.sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .container-fluid {
    display: block;
}

/* end level 1*/
/* start level 2*/
.sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/* end level 2*/
/* start level 3*/
.sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/* end level 3*/
/* start level 4*/
.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/*end level 4*/
/* start level 5*/
.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/*end level 5*/
/* start level 6*/
.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/*end level 6*/
/* start level 7*/
.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/*end level 7*/
/* start level 8*/
.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/*end level 8*/
/* start level 9*/
.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid {
    display: flex;
    flex-wrap: nowrap;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .container-fluid .row {
    flex: 0 0 auto;
}

.sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .sourcing .rules .container-fluid {
    display: block;
}

/*end level 9*/